var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cr-todo-labels-wrapper",on:{"click":function($event){return _vm.$emit('closeColorPicker')}}},[_c('div',{directives:[{name:"drag-and-drop",rawName:"v-drag-and-drop",value:({
      DRAG_COMP: 'label',
      genHelper: _vm.genHelper,
      dragstart: _vm.dragstart,
      genDropzone: _vm.genDropzone,
      markDropzone: _vm.markDropzone,
      mouseup: _vm.mouseup
    }),expression:"{\n      DRAG_COMP: 'label',\n      genHelper,\n      dragstart,\n      genDropzone,\n      markDropzone,\n      mouseup\n    }"}],staticClass:"cr-todo-labels",attrs:{"draggable":true}},_vm._l((_vm.labels),function(label,idx){return _c('v-hover',{key:label.value,scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [_c('div',{staticClass:"cr-label cr-draggable-label",class:_vm.isEdit ? ("cr-edit cr-edit-" + _vm.labelsLineCnt) : '',attrs:{"data-value":label.value}},[(!_vm.isEdit)?_c('v-list-item',{style:((" background: " + (label.color) + "; color: " + (_vm.invertColor(label.color)) + ";")),attrs:{"dense":""},on:{"click":function($event){return _vm.changeItemValue(label)}}},[_c('v-list-item-title',[_c('span',[_vm._v(" "+_vm._s(label.title)+" ")])])],1):_c('TextField',_vm._g({attrs:{"idx":idx,"label":label,"hover":hover,"header":_vm.header}},_vm.$listeners))],1)]}}],null,true)})}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }