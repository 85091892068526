<template>
  <v-row class="px-3 py-2">
    <v-col v-for="item in items" :key="item.type" cols="6">
      <v-btn
        block
        plain
        tile
        small
        @click="addHeader({ ...item, title: $t(item.title) })"
      >
        <div class="cr-btn-wrapper">
          <v-icon left dark>
            {{ item.icon }}
          </v-icon>
          {{ $t(item.title) }}
        </div>
      </v-btn>
    </v-col>
  </v-row>
</template>

<style lang="scss" scoped>
.cr-btn-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
</style>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import headerUtils from "@/todo/store/utils/header";

export default {
  props: {
    header: {
      type: Object,
      default: () => ({})
    },
    headerIdx: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      items: [
        { ...headerUtils.PERSON(), icon: "mdi-account-multiple-outline" },
        { ...headerUtils.STATUS(), icon: "mdi-playlist-check" },
        { ...headerUtils.TIMELINE(), icon: "mdi-chart-timeline" },
        { ...headerUtils.NUMBER(), icon: "mdi-counter" },
        { ...headerUtils.CREATED_LOG(), icon: "mdi-clock-outline" },
        { ...headerUtils.TEXT(), icon: "mdi-format-text" },
        { ...headerUtils.LINK(), icon: "mdi-link-variant" },
        { ...headerUtils.CHECK(), icon: "mdi-check-bold" }
      ]
    };
  },
  computed: {
    ...mapGetters("todoRoute", ["routeBoardId"])
  },
  methods: {
    ...mapMutations("todoMenu", ["CLOSE_MENU"]),
    ...mapActions("todoHeader", ["updateHeader"]),
    async addHeader(item) {
      this.CLOSE_MENU();
      let index = this.headerIdx;
      if (this.header.type !== headerUtils.ADDITIONAL().type) {
        index += 1;
      }

      // const value = type + "_" + new Date().getTime();
      let labels = [];
      // 상태 컬럼일 경우 기본 상태 label을 넣어준다.
      if (item.type == headerUtils.STATUS().type) {
        labels = [
          { title: "완료", color: "#66BB6A", value: `${item.value}_${0}` },
          { title: "진행중", color: "#FFEE58", value: `${item.value}_${1}` },
          { title: "위험", color: "#B71C1C", value: `${item.value}_${2}` },
          {
            title: "",
            color: "#f2f2f2",
            defaultLabel: true,
            value: `${item.value}_${3}`
          }
        ];
      }

      await this.updateHeader({
        mutation: "ADD_HEADER",
        boardId: this.routeBoardId,
        params: { index, header: { ...item, labels } }
      });

      this.$nextTick(() => {
        const todoList = document.getElementById("todoList");
        if (todoList && this.header.type == headerUtils.ADDITIONAL().type) {
          todoList.scrollLeft = todoList.scrollWidth - todoList.offsetWidth;
        }
      });
    }
  }
};
</script>
