import { render, staticRenderFns } from "./Labels.vue?vue&type=template&id=d8dd6bf2&scoped=true&"
import script from "./Labels.vue?vue&type=script&lang=js&"
export * from "./Labels.vue?vue&type=script&lang=js&"
import style0 from "./Labels.vue?vue&type=style&index=0&id=d8dd6bf2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8dd6bf2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VHover } from 'vuetify/lib/components/VHover';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VHover,VListItem,VListItemTitle})
