<template>
  <div class="cr-todo-timeline-content">
    <v-btn icon class="cr-left-btn" @click="prevMonth">
      <v-icon>mdi-arrow-left-thick</v-icon>
    </v-btn>
    <v-date-picker
      ref="startPicker"
      range
      no-title
      color="primary"
      class="st-date-picker"
      :day-format="dayFormat"
      v-model="startRange"
      @click:year="y => stPickerDate(y, 'year')"
      @click:month="m => stPickerDate(m, 'month')"
      @input="stInput"
    />
    <v-date-picker
      ref="endPicker"
      range
      no-title
      color="primary"
      class="ed-date-picker"
      :day-format="dayFormat"
      v-model="endRange"
      @click:year="y => edPickerDate(y, 'year')"
      @click:month="m => edPickerDate(m, 'month')"
      @input="edInput"
    />
    <v-btn icon class="cr-right-btn" @click="nextMonth">
      <v-icon>mdi-arrow-right-thick</v-icon>
    </v-btn>
  </div>
</template>

<style lang="scss" scoped>
.cr-todo-timeline-content {
  position: relative;
  padding: 7px 14px;
  width: 610px;

  .cr-left-btn {
    position: absolute;
    z-index: 1;
    top: 13px;
    left: 14px;
  }
  .cr-right-btn {
    position: absolute;
    z-index: 1;
    top: 13px;
    right: 14px;
  }

  .v-picker.v-card.v-picker--date::v-deep {
    > .v-picker__body .v-date-picker-table table {
      thead tr th,
      tbody tr td {
        border-left: none;
        border-right: none;

        > button.v-btn.v-btn--rounded {
          border-radius: 0;
          width: 37px;
          &.primary {
            background-color: rgba(106, 143, 255, 0.4) !important;
            &.v-date-picker--last-in-range,
            &.v-date-picker--first-in-range {
              background-color: var(--v-primary-base) !important;
            }
          }
        }
      }
    }

    .v-date-picker-header {
      > button {
        display: none;
      }
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getDateWithoutTime } from "@/commons/utils/moment";

export default {
  mounted() {
    this.initData();
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      startRange: [],
      endRange: [],
      df: "YYYY-MM-DD"
    };
  },
  computed: {
    ...mapGetters("todoMenu", [
      "itemId",
      "parentId",
      "groupId",
      "boardId",
      "headerValue",
      "autoClose"
    ]),
    ...mapGetters("todoItem", ["items", "subItems"]),
    item() {
      let items = this.items[this.groupId];
      if (this.parentId > 0) items = this.subItems[this.parentId];

      const [item] = items.filter(({ id }) => id == this.itemId);
      return item || {};
    }
  },
  watch: {
    itemId() {
      this.initData();
    },
    async startRange(startRange) {
      if (startRange.length == 2) {
        const { boardId, groupId, itemId, parentId, headerValue } = this;
        await this.updateItem({
          boardId,
          groupId,
          itemId,
          parentId,
          headerValue,
          itemValue: startRange.join(","),
          prevValue: this.item[headerValue]
        });

        // 자동닫기
        if (this.autoClose) this.CLOSE_MENU();
      }
    }
  },
  methods: {
    ...mapMutations("todoMenu", ["CLOSE_MENU"]),
    ...mapActions("todoItem", ["updateItem"]),
    dayFormat(date) {
      return new Date(date).getDate();
    },
    // 초기 타임라인 달력 설정
    initData() {
      const { headerValue, df } = this;
      let startRange = [];
      if (this.item[headerValue]) {
        startRange = this.item[headerValue].split(",");
      }

      // 저장된 item값으로 설정
      this.startRange = startRange;
      this.endRange = startRange;

      this.$nextTick(() => {
        // 오른쪽 달력 한달뒤로
        let date = new Date();
        const [d1] = startRange;
        if (d1) date = new Date(d1);
        this.startDate = getDateWithoutTime(date.getTime(), df);

        const m = date.getMonth();
        // 8월 31일 경우 9월 31일이 없어서 10월로 넘어감
        date.setDate(1);
        date.setMonth(m + 1);
        if (date.getMonth() == m) date.setDate(0);
        this.endDate = getDateWithoutTime(date.getTime(), df);

        this.$refs.startPicker.tableDate = this.startDate;
        this.$refs.endPicker.tableDate = this.endDate;
      });
    },

    // 날짜 눌렀을때 타는 함수
    stInput(range) {
      if (range.length == 1) {
        this.endRange = [];
      }

      if (
        range.length == 2 &&
        this.endRange.length == 1 &&
        this.$refs.startPicker.tableDate !== range[0]
      ) {
        this.endRange = range;
      }
    },
    // 년, 월 변경시 타는 함수
    stPickerDate(d, type) {
      let yyyymm = d;
      if (type == "year") {
        yyyymm = `${d}-${new Date(this.startDate).getMonth() + 1}`;
      }

      const date = new Date(yyyymm);
      const m = date.getMonth();
      date.setMonth(m + 1);
      if (date.getMonth() == m) date.setDate(0);

      this.startDate = getDateWithoutTime(new Date(yyyymm).getTime(), this.df);
      this.endDate = getDateWithoutTime(date.getTime(), this.df);
      if (this.$refs.endPicker) {
        this.$refs.endPicker.tableDate = this.endDate;
      }
    },

    // 날짜 눌렀을때 타는 함수
    edInput(range) {
      const { length } = range;
      const { length: startLength } = this.startRange;
      if (length == 1) {
        if (startLength == 0) {
          this.startRange[0] = range[0];
        } else if (startLength == 1) {
          this.startRange.push(range[0]);
          this.endRange = this.startRange;
        } else {
          this.startRange = [...range];
        }
      } else if (length == 2 && startLength == 1) {
        this.startRange = [...range];
      }
    },
    // 년, 월 변경시 타는 함수
    edPickerDate(d, type) {
      let yyyymm = d;
      if (type == "year") {
        yyyymm = `${d}-${new Date(this.endDate).getMonth() + 1}`;
      }
      let date = new Date(yyyymm);

      const m = date.getMonth();
      date.setMonth(m - 1);
      if (date.getMonth() == m) date.setDate(0);

      this.endDate = getDateWithoutTime(new Date(yyyymm).getTime(), this.df);
      this.startDate = getDateWithoutTime(date.getTime(), this.df);
      if (this.$refs.startPicker) {
        this.$refs.startPicker.tableDate = this.startDate;
      }
    },
    nextMonth() {
      const date = new Date(this.endDate);
      const m = date.getMonth();
      date.setMonth(m + 1);
      if (date.getMonth() == m) date.setDate(0);

      this.startDate = getDateWithoutTime(
        new Date(this.endDate).getTime(),
        this.df
      );
      this.endDate = getDateWithoutTime(date.getTime(), this.df);
      this.$refs.startPicker.tableDate = this.startDate;
      this.$refs.endPicker.tableDate = this.endDate;
    },
    prevMonth() {
      const date = new Date(this.startDate);
      const m = date.getMonth();
      date.setMonth(m - 1);
      if (date.getMonth() == m) date.setDate(0);

      this.endDate = getDateWithoutTime(
        new Date(this.startDate).getTime(),
        this.df
      );
      this.startDate = getDateWithoutTime(date.getTime(), this.df);

      if (this.startRange.length > 0) {
        this.endRange = this.startRange;
      }

      this.$refs.startPicker.tableDate = this.startDate;
      this.$refs.endPicker.tableDate = this.endDate;
    }
  }
};
</script>
