<template>
  <v-list outlined dense tile min-width="100">
    <v-list-item-group color="primary" v-model="selectedItem">
      <v-list-item dense>
        <v-list-item-title>
          <div>수정</div>
        </v-list-item-title>
      </v-list-item>
      <v-list-item dense>
        <v-list-item-title>
          <div>삭제</div>
        </v-list-item-title>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      selectedItem: undefined
    };
  },
  watch: {
    selectedItem(idx) {
      if (idx === 0) {
        this.SET_COMMENT_EDIT({ id: this.subType, edit: true });
        this.CLOSE_MENU();
      }

      if (idx === 1) {
        this.deleteCommentFunc();
      }
    }
  },
  computed: {
    ...mapGetters("todoRoute", ["routeBoardId"]),
    ...mapGetters("todoMenu", ["itemId", "boardId", "subType"])
  },
  methods: {
    ...mapMutations("todoMenu", ["SET_MENU", "CLOSE_MENU"]),
    ...mapMutations("todoDetail", ["SET_COMMENT_EDIT"]),
    ...mapActions("todoDetail", ["deleteComment"]),
    deleteCommentFunc() {
      const { itemId, boardId, subType: id } = this;
      this.deleteComment({ itemId, boardId, id });
      this.CLOSE_MENU();
    }
  }
};
</script>
