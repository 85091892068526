var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-text-field',{ref:"textRef",staticClass:"cr-label-text-field",class:_vm.showIcon ? 'cr-label-hover' : '',attrs:{"dense":"","hide-details":"","outlined":"","value":_vm.label.title,"placeholder":_vm.label.defaultLabel ? '기본 라벨' : '라벨 추가'},on:{"blur":_vm.updateTitle,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updateTitle.apply(null, arguments)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-btn',{attrs:{"icon":"","small":"","disabled":_vm.label.defaultLabel,"ripple":false},on:{"click":function($event){$event.stopPropagation();return (function (e) { return _vm.$emit('openColorPicker', e, _vm.label, _vm.idx); }).apply(null, arguments)}}},[_c('v-icon',{style:(("border: thin solid " + (_vm.label.color) + "; cursor: pointer;")),attrs:{"color":_vm.label.color},domProps:{"textContent":_vm._s('mdi-brush-variant')}})],1)]},proxy:true},{key:"prepend",fn:function(){return [_c('div',{staticStyle:{"width":"18px","height":"18px"}},[(_vm.showIcon)?_c('v-icon',[_vm._v("mdi-drag")]):_vm._e()],1)]},proxy:true},{key:"append-outer",fn:function(){return [_c('div',{staticClass:"cr-tooltip",staticStyle:{"width":"18px","height":"18px"},on:{"mouseover":function (event) { return _vm.SET_TOOLTIP({
            msg:
              _vm.label.deletable || _vm.label.defaultLabel
                ? ''
                : '사용 중인 라벨은 삭제할 수 없습니다.',
            position: 'top',
            event: event
          }); },"mouseout":_vm.HIDE_TOOLTIP}},[(_vm.showIcon && !_vm.label.defaultLabel)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteLabel(_vm.label)}}},[_vm._v(" mdi-close ")]):_vm._e()],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }