<template>
  <div class="cr-label-menu-footer">
    <v-btn v-if="!isEdit" text block tile @click="$emit('update:isEdit', true)">
      <v-icon size="17" class="pr-1">
        mdi-pencil-outline
      </v-icon>
      라벨 수정
    </v-btn>
    <!-- 버튼 -->
    <div v-else class="d-flex align-center justify-center">
      <v-btn class="mr-1 v-btn-light" depressed width="96" @click="addLabel">
        <h4>추가</h4>
      </v-btn>
      <v-btn
        depressed
        width="96"
        class="v-btn-light primary"
        @click="$emit('update:isEdit', false)"
      >
        <h4>확인</h4>
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-label-menu-footer {
  background: #fff;
  padding-top: 8px;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    labels: {
      type: Array,
      default: () => []
    },
    header: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("todoMenu", [
      "itemId",
      "groupId",
      "boardId",
      "itemValue",
      "headerValue"
    ])
  },
  methods: {
    ...mapActions("todoHeader", ["updateHeader"]),
    addLabel() {
      if (!this.isEdit) return;
      this.updateHeader({
        mutation: "CHANGE_HEADER",
        boardId: this.boardId,
        params: {
          value: this.headerValue,
          header: {
            ...this.header,
            labels: [
              ...this.labels,
              {
                value: this.headerValue + "_" + new Date().getTime(),
                title: "",
                color: "#F44336"
              }
            ]
          }
        }
      });
      this.$emit("updateDimensions");
    }
  }
};
</script>
