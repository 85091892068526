<template>
  <v-card flat elevation="0" class="cr-add-header cr-drag-prevent">
    <v-card-title class="mb-4">
      표시 형식
    </v-card-title>
    <v-card-subtitle>
      단위
    </v-card-subtitle>
    <v-card-text class="justify-center">
      <v-btn-toggle v-model="format" dense>
        <v-btn
          value="default"
          @click="changeHeader({ format: 'default', combinedFormat })"
        >
          <span class="hidden-sm-and-down">기간</span>
        </v-btn>

        <v-btn
          value="percent"
          @click="changeHeader({ format: 'percent', combinedFormat })"
        >
          <span class="hidden-sm-and-down">진척률</span>
        </v-btn>
      </v-btn-toggle>
    </v-card-text>
    <v-card-subtitle>
      종합
    </v-card-subtitle>
    <v-card-text class="justify-center">
      <v-btn-toggle v-model="combinedFormat" dense>
        <v-btn
          value="column"
          @click="changeHeader({ format, combinedFormat: 'column' })"
        >
          <span class="hidden-sm-and-down">
            {{ format == "default" ? "열 합" : "열 평균" }}
          </span>
        </v-btn>

        <v-btn
          value="row"
          @click="changeHeader({ format, combinedFormat: 'row' })"
        >
          <span class="hidden-sm-and-down">
            {{
              format == "default"
                ? "타임라인 종합 기간"
                : "타임라인 종합 진척률"
            }}
          </span>
        </v-btn>
      </v-btn-toggle>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
.cr-drag-prevent {
  cursor: default;
}
.cr-btn-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    header: {
      type: Object,
      default: () => ({})
    }
  },
  data(vue) {
    return {
      format: vue.$props?.header?.format ?? "default",
      combinedFormat: vue.$props?.header?.combinedFormat ?? "column"
    };
  },
  computed: {
    ...mapGetters("todoRoute", ["routeBoardId"]),
    isConnected() {
      return !!this.header.connectedComlumn;
    }
  },
  watch: {
    format(next, prev) {
      if (!next) {
        setTimeout(() => {
          this.format = prev;
        }, 0);
      }
    },
    combinedFormat(next, prev) {
      if (!next) {
        setTimeout(() => {
          this.combinedFormat = prev;
        }, 0);
      }
    }
  },
  methods: {
    ...mapActions("todoHeader", ["updateHeader"]),
    changeHeader({ format, combinedFormat }) {
      if (
        !this.header.format ||
        this.header.format !== format ||
        !this.header.combinedFormat ||
        this.header.combinedFormat !== combinedFormat
      ) {
        this.updateHeader({
          mutation: "CHANGE_HEADER",
          boardId: this.routeBoardId,
          params: {
            header: { format, combinedFormat },
            value: this.header.value
          }
        });
      }
    }
  }
};
</script>
