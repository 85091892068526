<template>
  <v-text-field
    ref="textRef"
    dense
    hide-details
    outlined
    class="cr-label-text-field"
    :class="showIcon ? 'cr-label-hover' : ''"
    :value="label.title"
    :placeholder="label.defaultLabel ? '기본 라벨' : '라벨 추가'"
    @blur="updateTitle"
    @keydown.enter="updateTitle"
  >
    <template v-slot:prepend-inner>
      <v-btn
        icon
        small
        :disabled="label.defaultLabel"
        :ripple="false"
        @click.stop="e => $emit('openColorPicker', e, label, idx)"
      >
        <v-icon
          :color="label.color"
          :style="`border: thin solid ${label.color}; cursor: pointer;`"
          v-text="'mdi-brush-variant'"
        />
      </v-btn>
    </template>
    <template v-slot:prepend>
      <div style="width:18px; height:18px;">
        <v-icon v-if="showIcon">mdi-drag</v-icon>
      </div>
    </template>
    <template v-slot:append-outer>
      <div
        class="cr-tooltip"
        style="width:18px; height:18px;"
        @mouseover="
          event =>
            SET_TOOLTIP({
              msg:
                label.deletable || label.defaultLabel
                  ? ''
                  : '사용 중인 라벨은 삭제할 수 없습니다.',
              position: 'top',
              event
            })
        "
        @mouseout="HIDE_TOOLTIP"
      >
        <v-icon
          v-if="showIcon && !label.defaultLabel"
          small
          @click="deleteLabel(label)"
        >
          mdi-close
        </v-icon>
      </div>
    </template>
  </v-text-field>
</template>

<style lang="scss" scoped>
.v-input.v-text-field.cr-label-text-field::v-deep {
  margin: 2px;

  .v-input__prepend-outer {
    margin-right: 0px;
    cursor: move;
  }

  .v-input__control {
    flex-grow: 0;
    > .v-input__slot {
      > .v-input__prepend-inner {
        margin-top: 6px;
        .v-btn:before {
          opacity: 0;
        }
      }
    }
  }

  .v-input__append-outer {
    i {
      cursor: pointer;
    }

    margin-top: 10px !important;
    margin-left: 0px;
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  props: {
    idx: {
      type: Number,
      default: -1
    },
    hover: {
      type: Boolean,
      default: false
    },
    label: {
      type: Object,
      default: () => ({})
    },
    header: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("todoMenu", ["headerValue", "boardId"]),
    ...mapGetters("dragDrop", ["dragMode"]),
    showIcon() {
      return !this.dragMode && this.hover;
    }
  },
  methods: {
    ...mapMutations("todoTooltip", ["SET_TOOLTIP", "HIDE_TOOLTIP"]),
    ...mapActions("todoHeader", ["updateHeader"]),
    updateTitle(e) {
      const { title, value: labelValue } = this.label;
      const newTitle = e.target.value.trim();
      if (title == newTitle) return;

      const labels = this.header.labels.map(l => ({ ...l }));
      const [label] = labels.filter(l => l.value == labelValue);
      if (!label) return;

      label.title = newTitle;
      this.updateHeader({
        mutation: "CHANGE_HEADER",
        boardId: this.boardId,
        params: {
          value: this.headerValue,
          header: { ...this.header, labels }
        }
      });
      this.$refs.textRef.blur();
    },
    deleteLabel(label) {
      if (label.defaultLabel || !label.deletable) return;
      const { labels } = this.header;

      this.updateHeader({
        mutation: "CHANGE_HEADER",
        boardId: this.boardId,
        params: {
          value: this.headerValue,
          header: {
            ...this.header,
            labels: labels.filter(({ value }) => label.value !== value)
          }
        }
      });
      this.$emit("updateDimensions");
    }
  }
};
</script>
