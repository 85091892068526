<template>
  <v-card
    elevation="3"
    :class="
      `cr-todo-menu cr-todo-${type} ${showDetail ? 'cr-todo-detail' : ''}`
    "
    :style="style"
    @mousedown.stop
    v-click-outside="clickOutside"
  >
    <div id="menu-tooltip" :class="menuTooltipCls" />
    <LabelMenu
      v-if="type == 'label'"
      ref="labelmenu"
      @updateDimensions="updateDimensions"
    />
    <PersonMenu
      v-if="type == 'person'"
      ref="personmenu"
      @updateDimensions="updateDimensions"
    />
    <TimelineMenu
      v-if="type == 'timeline'"
      ref="timelinemenu"
      @updateDimensions="updateDimensions"
    />
    <HeaderMenu
      v-if="type == 'header'"
      ref="header"
      @updateDimensions="updateDimensions"
    />
    <CommentMenu
      v-if="type == 'comment'"
      ref="comment"
      @updateDimensions="updateDimensions"
    />
  </v-card>
</template>

<style lang="scss" scoped>
.cr-todo-menu {
  position: absolute;
  // z-index: 4;
  z-index: 6;
  &.cr-todo-detail {
    z-index: 7;
  }

  #menu-tooltip {
    display: none;
    position: absolute;
    transform: rotate(45deg);
    z-index: 0;
    width: 16px;
    height: 16px;
    background-color: #fff;
    &.top {
      display: block;
      box-shadow: inset 1px 1px 1px 0px rgb(0 0 0 / 6%);
      top: -8px;
      left: calc(50% - 8px);
    }
    &.bottom {
      display: block;
      box-shadow: inset -1px -1px 0px 0px rgb(0 0 0 / 6%);
      bottom: -8px;
      left: calc(50% - 8px);
    }
    &.right {
      display: block;
      left: unset;
      right: 5px;
    }
  }
}
</style>

<script>
import { mapGetters, mapMutations } from "vuex";
import LabelMenu from "./menu/LabelMenu.vue";
import PersonMenu from "./menu/PersonMenu.vue";
import TimelineMenu from "./menu/TimelineMenu.vue";
import HeaderMenu from "./menu/HeaderMenu.vue";
import CommentMenu from "./menu/CommentMenu.vue";

export default {
  components: {
    LabelMenu,
    PersonMenu,
    TimelineMenu,
    HeaderMenu,
    CommentMenu
  },
  mounted() {
    document.addEventListener("keydown", this.esc);
    window.addEventListener("resize", this.updateDimensions);
  },
  destroyed() {
    document.removeEventListener("keydown", this.esc);
    window.removeEventListener("resize", this.updateDimensions);
  },
  data() {
    return {
      right: "",
      style: "",
      menuTooltipCls: ""
    };
  },
  computed: {
    ...mapGetters("todoMenu", ["show", "type", "position"]),
    ...mapGetters("todoDetail", { showDetail: "show" })
  },
  watch: {
    position(n) {
      if (Object.keys(n).length === 0) return;
      this.updateDimensions();
    },
    type(n) {
      if (n) return;
      this.style = "";
      this.menuTooltipCls = "";
    }
  },
  methods: {
    ...mapMutations("todoMenu", ["CLOSE_MENU"]),
    clickOutside(e) {
      if (e.target.closest(".cr-menu-close-prevent")) return;
      this.CLOSE_MENU();
    },
    esc(e) {
      if (e.keyCode !== 27) return;
      if (this.type === "label") {
        const { labelmenu } = this.$refs;
        const { colorPicker } = labelmenu;
        if (colorPicker.show) {
          labelmenu.closeColorPicker();
          return;
        }
      }

      this.CLOSE_MENU();
    },
    updateDimensions() {
      this.$nextTick(() => {
        if (!this.type) return;
        const {
          offsetWidth: listWidth,
          offsetHeight: listHeight,
          scrollTop,
          scrollLeft
        } = document.getElementById("todoList");
        const { offsetWidth: menuWidth, offsetHeight: menuHeight } = this.$el;
        const { top, left, targetWidth, targetHeight } = this.position;
        if (top === undefined || left === undefined) return;
        let style = "";

        const todoHeaderHeight = this.showDetail ? 74 : 0;
        if (listHeight < top + menuHeight) {
          style += `top: ${top +
            scrollTop -
            menuHeight -
            targetHeight +
            todoHeaderHeight}px;`;
          this.menuTooltipCls = "bottom";
        } else {
          style += `top: ${top + scrollTop + todoHeaderHeight}px;`;
          this.menuTooltipCls = "top";
        }

        // 모달창 열릴때 오른쪽 공간 없을때 처리
        if (left + menuWidth >= listWidth) {
          style += ` right: ${listWidth - left - scrollLeft - targetWidth}px;`;
          this.menuTooltipCls += " right";
        } else {
          style += ` left: ${left +
            scrollLeft -
            menuWidth / 2 +
            targetWidth / 2}px;`;
        }

        this.style = style;
      });
    }
  }
};
</script>
