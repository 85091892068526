<template>
  <div class="cr-todo-header-content">
    <Add v-if="subType == 'add'" :header="header" :headerIdx="headerIdx" />
    <Menu v-if="subType == 'menu'" :header="header" :headerIdx="headerIdx" />
    <NumberFormat v-if="subType == 'numberFormat'" :header="header" />
  </div>
</template>

<style lang="scss" scoped>
.cr-todo-header-content {
  max-width: 270px;
}
</style>

<script>
import { mapGetters } from "vuex";
import Add from "./header/Add.vue";
import Menu from "./header/Menu.vue";
import NumberFormat from "./header/NumberFormat.vue";

export default {
  components: { Add, Menu, NumberFormat },
  computed: {
    ...mapGetters("todoMenu", ["subType", "headerValue"]),
    ...mapGetters("todoHeader", { headers: "header" }),
    header() {
      const [header] = this.headers.filter(h => h.value === this.headerValue);
      return header ?? {};
    },
    headerIdx() {
      return this.headers.findIndex(h => h.value === this.headerValue);
    }
  },
  watch: {
    subType() {
      this.$nextTick(() => this.$emit("updateDimensions"));
    }
  }
};
</script>
