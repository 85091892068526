<template>
  <v-color-picker
    :style="style"
    :swatches-max-height="200"
    hide-canvas
    hide-mode-switch
    hide-sliders
    hide-inputs
    show-swatches
    v-model="labelColor"
  />
</template>

<style lang="scss" scoped>
.v-color-picker {
  display: none;
  position: absolute;
  z-index: 1;
  width: 300px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    colorPicker: {
      type: Object,
      default: () => ({})
    },
    header: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters("todoMenu", ["boardId"]),
    labelColor: {
      get() {
        const { label } = this.colorPicker;
        return label?.color ?? "#FF0000FF";
      },
      set(color) {
        this.updateLabelColor(color);
      }
    },
    style() {
      const {
        offsetTop,
        offsetLeft,
        scrollLeft,
        menuRight,
        contentWidth
      } = this.colorPicker;
      if (!this.colorPicker.show) return "display: none;";

      const { offsetWidth: rootWidth } = this.$root.$el;
      let left = offsetLeft - scrollLeft;
      // 오른쪽에 남는 공간 없을때
      if (left + menuRight > rootWidth) {
        // 300은 피커의 width
        const pickerWidth = 300;
        // picker가 오른쪽 튀어나오는 크기
        const rightWidth = pickerWidth - (contentWidth - left);
        // 오른쪽 남는 공간
        const extraWidth = rootWidth - menuRight;

        if (rightWidth > extraWidth) left -= rightWidth;
      }

      return `display: block; top: ${offsetTop + 40}px; left: ${left}px`;
    }
  },
  methods: {
    ...mapActions("todoHeader", ["updateHeader"]),
    updateLabelColor(color) {
      const { value: headerValue, labels } = this.header;
      const newLabels = labels.map(l => ({ ...l }));
      const {
        label: { value }
      } = this.colorPicker;
      const [label] = newLabels.filter(l => l.value == value);
      if (label) label.color = color;

      this.updateHeader({
        mutation: "CHANGE_HEADER",
        boardId: this.boardId,
        params: {
          value: headerValue,
          header: { ...this.header, labels: newLabels }
        }
      });
      this.$emit("closeColorPicker");
    }
  }
};
</script>
