<template>
  <v-list outlined dense tile>
    <v-list-item-group color="primary">
      <div style="display: contents;" v-for="(i, idx) in items" :key="idx">
        <v-list-item dense @click="i.click">
          <v-list-item-title>{{ i.title }}</v-list-item-title>
        </v-list-item>
      </div>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import headerUtils from "@/todo/store/utils/header";

export default {
  props: {
    header: {
      type: Object,
      default: () => ({})
    },
    headerIdx: {
      type: Number,
      default: -1
    }
  },
  computed: {
    ...mapGetters("todoRoute", ["routeBoardId"]),
    ...mapGetters("todoMenu", ["headerValue", "isDetail"]),
    items() {
      const { title, type, connectedColumn } = this.header;
      const additionalMenu = [];
      if (type === headerUtils.TIMELINE().type) {
        additionalMenu.push({
          title: "숫자와 연결",
          click: () => {
            this.CLOSE_MENU();
            this.SET_DIALOG({
              show: true,
              type: "connectColumn",
              headline: "숫자컬럼 선택",
              params: {
                boardId: this.routeBoardId,
                headerValue: this.header.value
              }
            });
          }
        });
      }

      if (type === headerUtils.NUMBER().type && connectedColumn) {
        additionalMenu.push({
          title: "표시 형식 변경",
          click: () => this.SET_MENU({ subType: "numberFormat" })
        });
      }

      return [
        {
          title: this.isDetail ? "아래에 컬럼 추가" : "오른쪽에 컬럼 추가",
          click: () => this.SET_MENU({ subType: "add" })
        },
        ...additionalMenu,
        {
          title: "삭제",
          click: () => {
            this.CLOSE_MENU();
            this.confirm({
              message: `"${title}" 컬럼을 삭제하시겠습니까?`,
              callback: () => {
                this.updateHeader({
                  boardId: this.routeBoardId,
                  mutation: "DELETE_HEADER",
                  params: { index: this.headerIdx }
                });
              },
              showCloseBtn: true
            });
          }
        }
      ];
    }
  },
  methods: {
    ...mapMutations("todoMenu", ["SET_MENU", "CLOSE_MENU"]),
    ...mapMutations("todoDialog", ["SET_DIALOG"]),
    ...mapActions("confirm", ["confirm", "disagree", "agree"]),
    ...mapActions("todoHeader", ["updateHeader"])
  }
};
</script>
