<template>
  <div class="cr-todo-labels-wrapper" @click="$emit('closeColorPicker')">
    <div
      class="cr-todo-labels"
      :draggable="true"
      v-drag-and-drop="{
        DRAG_COMP: 'label',
        genHelper,
        dragstart,
        genDropzone,
        markDropzone,
        mouseup
      }"
    >
      <v-hover
        v-for="(label, idx) in labels"
        v-slot="{ hover }"
        :key="label.value"
      >
        <div
          class="cr-label cr-draggable-label"
          :class="isEdit ? `cr-edit cr-edit-${labelsLineCnt}` : ''"
          :data-value="label.value"
        >
          <!-- 라벨 리스트 아이템 -->
          <v-list-item
            v-if="!isEdit"
            dense
            :style="
              ` background: ${label.color}; color: ${invertColor(label.color)};`
            "
            @click="changeItemValue(label)"
          >
            <v-list-item-title>
              <span> {{ label.title }} </span>
            </v-list-item-title>
          </v-list-item>

          <!-- 수정 텍스트 필드 -->
          <TextField
            v-else
            :idx="idx"
            :label="label"
            :hover="hover"
            :header="header"
            v-on="$listeners"
          />
        </div>
      </v-hover>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-todo-labels-wrapper {
  overflow: auto hidden;

  > .cr-todo-labels {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 288px;

    .cr-label {
      display: flex;
      width: 200px;
      .v-list-item {
        margin: 2px;
        width: 190px;
      }
      &.cr-edit {
        width: 207px;
      }
      &.cr-edit-1 {
        width: 230px;
      }
      &.cr-edit-2 {
        width: 215px;
      }
      &.cr-edit-3 {
        width: 210px;
      }
      &.cr-drag-target {
        .v-input.cr-label-text-field::v-deep {
          .v-input__control .v-input__slot {
            fieldset {
              border: 1px solid var(--v-primary-base);
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { invertColor } from "@/commons/utils/invertColor";
import TextField from "./TextField.vue";

export default {
  components: { TextField },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    labels: {
      type: Array,
      default: () => []
    },
    header: {
      type: Object,
      default: () => ({})
    },
    labelsLineCnt: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters("dragDrop", ["dragComp", "dragMode"]),
    ...mapGetters("todoMenu", [
      "itemId",
      "parentId",
      "groupId",
      "boardId",
      "itemValue",
      "headerValue"
    ])
  },
  methods: {
    ...mapMutations("todoMenu", ["CLOSE_MENU"]),
    ...mapActions("todoItem", ["updateItem"]),
    ...mapActions("todoHeader", ["updateHeader"]),
    invertColor(color) {
      return invertColor(color);
    },
    changeItemValue(label) {
      if (this.isEdit) return;

      this.updateItem({
        itemId: this.itemId,
        parentId: this.parentId,
        groupId: this.groupId,
        boardId: this.boardId,
        headerValue: this.headerValue,
        itemValue: label.value,
        prevValue: this.itemValue
      });
      this.CLOSE_MENU();
    },

    // drag & drop
    genHelper(e, setDragInfo) {
      const dragTarget = e.target.closest(".cr-draggable-label");
      if (
        !dragTarget ||
        !(e.target.tagName === "I" && e.target.classList.contains("mdi-drag"))
      ) {
        return null;
      }

      setDragInfo({ dragTarget });
      return document.createElement("div");
    },
    dragstart(e, setDragInfo, dragInfo) {
      if (!dragInfo.dragTarget) return;
      document.body.classList.add("no-drop");
      dragInfo.dragTarget.classList.add("cr-drag-target");
    },
    genDropzone() {
      if (this.dragComp == "label") {
        this.$el.querySelectorAll(".cr-draggable-label").forEach(el => {
          el.classList.add("cr-drop-cursor");
        });
      }
    },
    markDropzone(e, dragInfo) {
      if (this.dragComp == "label") {
        const { dragTarget } = dragInfo;
        const target = e.target.closest(".cr-draggable-label");
        const list = e.target.closest(".cr-todo-labels");

        if (!target) {
          dragInfo["dropTarget"] = "";
          dragInfo["position"] = "";
        }

        if (target && dragTarget != target) {
          dragInfo["dropTarget"] = target;
          const dropTargetIndex = list.children.indexOf(target);
          const dragTargetIndex = list.children.indexOf(dragTarget);
          const { nextSibling } = target;

          if (dragTargetIndex < dropTargetIndex) {
            dragInfo["position"] = "after";
            if (nextSibling) {
              list.insertBefore(dragTarget, nextSibling);
            } else {
              list.appendChild(dragTarget);
            }
          } else {
            dragInfo["position"] = "before";
            list.insertBefore(dragTarget, target);
          }
        }
      }
    },
    mouseup(e, { dropTarget, dragTarget, position }) {
      if (dropTarget) {
        const labels = [...this.labels];
        const dropValue = dropTarget.dataset.value;
        const dragValue = dragTarget.dataset.value;
        const dragIndex = labels.findIndex(({ value }) => value == dragValue);
        let tmp = labels[dragIndex];
        labels.splice(dragIndex, 1);
        let dropIndex = labels.findIndex(({ value }) => value == dropValue);
        if (position == "after") {
          dropIndex += 1;
        }

        labels.splice(dropIndex, 0, tmp);
        this.updateHeader({
          mutation: "CHANGE_HEADER",
          boardId: this.boardId,
          params: {
            value: this.headerValue,
            header: { ...this.header, labels }
          }
        });
      }

      dragTarget.classList.remove("cr-drag-target");
      document.body.classList.remove("no-drop");
      // 드랍가능영역 제거
      const dropList = document.getElementsByClassName("cr-drop-cursor");
      while (dropList.length > 0) {
        dropList[0].classList.remove("cr-drop-cursor");
      }
    }
  }
};
</script>
