<template>
  <div class="cr-todo-person-content">
    <Autocomplete
      key="todo_person"
      :selection.sync="selection"
      :listHeight="250"
      :directInput="false"
      :useChips="false"
      :placeholder="'검색할 멤버를 입력해주세요.'"
      :typeList="['MEMBER']"
    />
    <div
      class="d-flex"
      style="margin-top: 2px; padding-bottom: 3px; min-height: 38px; max-height: 94px; overflow: hidden auto; border: 1px solid #dfdfdf;"
    >
      <div
        style="width: 50px; min-width: 50px; text-align: center; line-height: 38px;"
      >
        추천인
      </div>
      <div style="padding: 3px 4px;">
        <v-chip
          v-for="recommendedMember in recommendedMembers"
          :key="recommendedMember.userId"
          small
          color="primary"
          class="px-2 mr-1 mt-1"
          @click="addMember(recommendedMember)"
        >
          {{ recommendedMember.name }}
        </v-chip>
      </div>
    </div>
    <!-- border: 1px solid #dfdfdf; -->
    <div style="margin-top: 2px; max-height: 256px; overflow: auto;">
      <v-list single-line dense>
        <v-list-item v-for="member in members" :key="member.userId">
          <v-list-item-content>
            <v-list-item-title class="subtitle-1">
              {{ `"${member.name}" ${member.email}` }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn icon @click="deleteMember(member)">
              <v-icon small color="grey lighten-1">mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-if="members.length == 0">
          <v-list-item-content>
            <v-list-item-title>
              등록된 사람이 없습니다.
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cr-todo-person-content {
  position: relative;
  width: 440px;
  height: 380px;
  padding: 10px 15px;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import Autocomplete from "@/commons/views/autocomplete/Autocomplete";

export default {
  components: { Autocomplete },
  data() {
    return {
      selection: {}
    };
  },
  computed: {
    ...mapGetters("todoItem", ["items", "subItems", "recommender"]),
    ...mapGetters("todoMenu", [
      "headerValue",
      "groupId",
      "itemId",
      "boardId",
      "parentId",
      "headerValue"
    ]),
    item() {
      let items = this.items[this.groupId];
      if (this.parentId > 0) items = this.subItems[this.parentId];

      const [item] = items.filter(({ id }) => id == this.itemId);
      return item || {};
    },
    members() {
      try {
        return (
          this.item[this.headerValue]
            ?.split(";")
            ?.map(member => JSON.parse(member)) ?? []
        );
      } catch (e) {
        return [];
      }
    },
    recommendedMembers() {
      return (
        this.recommender[this.headerValue]?.filter(
          o => this.members.findIndex(m => m.userId == o.userId) == -1
        ) ?? []
      );
    }
  },
  watch: {
    selection(selection) {
      if (selection) {
        const { name, email, userId } = selection;
        this.addMember({ name, email, userId });
      }
    }
  },
  methods: {
    ...mapActions("todoItem", ["updateItem"]),
    addMember(member) {
      const [prevMember] = this.members.filter(m => m.userId == member.userId);
      if (prevMember) return;

      this.updateItem({
        itemId: this.itemId,
        parentId: this.parentId,
        groupId: this.groupId,
        boardId: this.boardId,
        headerValue: this.headerValue,
        itemValue: [...this.members, member]
          .map(member => JSON.stringify(member))
          .join(";"),
        prevValue: this.members.map(member => JSON.stringify(member)).join(";")
      });
    },
    deleteMember(member) {
      this.updateItem({
        itemId: this.itemId,
        parentId: this.parentId,
        groupId: this.groupId,
        boardId: this.boardId,
        headerValue: this.headerValue,
        itemValue: this.members
          .filter(m => m.userId !== member.userId)
          .map(member => JSON.stringify(member))
          .join(";"),
        prevValue: this.members.map(member => JSON.stringify(member)).join(";")
      });
    }
  }
};
</script>
