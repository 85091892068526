<template>
  <div
    class="cr-todo-label-content"
    :class="isEdit ? 'cr-edit' : ''"
    :style="`width: ${this.contentWidth}px`"
  >
    <Labels
      ref="labelsW"
      :isEdit="isEdit"
      :header="header"
      :labels="labels"
      :labelsLineCnt="labelsLineCnt"
      v-on="$listeners"
      @openColorPicker="openColorPicker"
      @closeColorPicker="closeColorPicker"
    />
    <ColorPicker
      :header="header"
      :colorPicker="colorPicker"
      @closeColorPicker="closeColorPicker"
    />
    <Buttons
      :isEdit.sync="isEdit"
      :header="header"
      :labels="labels"
      v-on="$listeners"
    />
  </div>
</template>

<style lang="scss" scoped>
.cr-todo-label-content {
  position: relative;
  max-width: 830px;
  min-width: 230px;
  max-height: 365px;
  padding: 10px 15px;

  &.cr-edit {
    padding: 10px 0px;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import Labels from "./label/Labels.vue";
import ColorPicker from "./label/ColorPicker.vue";
import Buttons from "./label/Buttons.vue";

export default {
  components: { Labels, ColorPicker, Buttons },
  data() {
    return {
      isEdit: false,
      colorPicker: {
        show: false
      }
    };
  },
  computed: {
    ...mapGetters("todoMenu", ["menuState"]),
    ...mapGetters("todoItem", ["items"]),
    ...mapGetters("todoHeader", { headers: "header" }),
    header() {
      const { headerValue } = this.menuState;
      const [h] = this.headers.filter(h => h.value === headerValue);
      return h;
    },
    labels() {
      const { headerValue } = this.menuState;
      if (!this.header || !Array.isArray(this.header.labels)) return [];

      // label 삭제 여부 확인 -> 사용중인 label은 삭제하지 못한다.
      const labels = this.header.labels.map(l => ({ ...l, deletable: true }));
      Object.keys(this.items).forEach(key => {
        this.items[key].forEach(item => {
          if (!item[headerValue]) return;

          const [useLabel] = labels.filter(l => l.value === item[headerValue]);
          if (useLabel) useLabel["deletable"] = !useLabel;
        });
      });

      return labels;
    },
    labelsLineCnt() {
      const limit = 6;
      const { length } = this.labels;
      return (
        Math.floor(length / limit) + (Math.floor(length % limit) > 0 ? 1 : 0)
      );
    },
    contentWidth() {
      const labelWidth = 200;
      const paddingX = 30; // cr-todo-label-content 패딩
      return labelWidth * this.labelsLineCnt + paddingX;
    }
  },
  methods: {
    openColorPicker(e, label, idx) {
      const target = e.target.closest(".cr-label.cr-edit");
      if (!target) return;

      // menuRight는 메뉴창의 오른쪽 위치
      this.colorPicker = {
        show: true,
        label,
        idx,
        menuRight: this.$el.getBoundingClientRect().right,
        offsetTop: target.offsetTop,
        offsetLeft: target.offsetLeft,
        scrollLeft: this.$refs.labelsW.$el.scrollLeft,
        contentWidth: this.contentWidth > 830 ? 830 : this.contentWidth
      };
    },
    closeColorPicker() {
      this.colorPicker.show = false;
    }
  }
};
</script>
